import React, { useEffect } from "react";
import "../../assets/css/landing-page.css";
import Logo from "../../assets/images/logo-header.svg";
import GooglePlay from "../../assets/images/Google-Play-Badge.svg";
import AppStore from "../../assets/images/App-Store-Badge.svg";
import PhoneMockup1 from "../../assets/images/iPhone-X-1.png";
import PhoneMockup2 from "../../assets/images/iPhone-X-2.png";
import PhoneMockup3 from "../../assets/images/iPhone-X-3.png";
import LogoWhite from "../../assets/images/logo-white.svg";
import Instagram from "../../assets/images/Instagram.svg";
import Dribbble from "../../assets/images/Dribbble.svg";
import Twitter from "../../assets/images/Twitter.svg";
import Youtube from "../../assets/images/Youtube.svg";
import LinkedIn from "../../assets/images/linkedin.svg";
import VideoPreview from "../../assets/images/video-preivew.png";
import PlayBtn from "../../assets/images/play-button.svg";

import { Link } from "react-scroll";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Api from "../../helper/Api";
import { statusOK } from "../../helper/constants";
import { NavLink } from "react-router-dom";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

export function LandingPage() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const onSubmit = (params) => {
    let user_captcha_value =
      document.getElementById("user_captcha_input").value;
    if (user_captcha_value.length === 0) {
      toast.error("Please enter captcha!");
      return;
    }
    if (validateCaptcha(user_captcha_value) === true) {
      sendMail(params);
    } else {
      toast.error("Captcha Does Not Match");
      document.getElementById("user_captcha_input").value = "";
    }
  };

  const sendMail = (mailData) => {
    const templateParams = {
      name: "",
      email: "",
      phone_number: "",
      message: "",
    };
    Api("POST", "contact-us/add", mailData)
      .then((res) => {
        if (res.status === statusOK) {
          toast.success("Message has been sent!");
          reset(templateParams);
          document.getElementById("user_captcha_input").value = "";
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // Similar to componentDidMount and componentDidUpdate:
    loadCaptchaEnginge(6, "black", "white");
  });

  return (
    <div className="main-container">
      {/* main landing page */}
      <section className="main-landing" id="home">
        <header>
          <div className="inner-container">
            <NavLink to="/">
              <img src={Logo} alt="logo" className="logo" />
            </NavLink>
            <span>
              <Link
                to="home"
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
              >
                Home
              </Link>
            </span>
            <span>
              <Link
                to="about_us"
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
              >
                Features
              </Link>
            </span>
            <span>
              <Link
                to="get-in-touch"
                spy={true}
                smooth={true}
                duration={500}
                hashSpy={true}
                activeClass="active"
                offset={-300}
              >
                Get in Touch
              </Link>
            </span>
            <span>
              <NavLink to="/privacy">Privacy</NavLink>
            </span>
          </div>
        </header>
        <div className="inner-container">
          <div className="main-sec">
            <div className="left-sec">
              <p>
                It is a long <br /> established fact
              </p>
              <p>
                It has survived not only five centuries, but also the leap into{" "}
                <br />
                electronic typesetting, remaining essentially unchanged.
              </p>
              <div className="badges">
                <img src={GooglePlay} alt="" />
                <img src={AppStore} alt="" />
              </div>
            </div>
            <div className="right-sec">
              <img src={PhoneMockup1} alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* About us page */}
      <section className="about-us" id="about_us">
        <div className="inner-container">
          <p className="heading">Features</p>
          <p className="sub-txt">
            Lorem Ipsum is simply dummy text of the printing
          </p>
          <div className="card">
            <p className="heading">
              Lorem Ipsum is simply dummy text of the printing
            </p>
            <p className="sub-txt">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing
            </p>
            <div className="video-preview">
              <a
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=D0UnqGm_miA&ab_channel=ShakeelMureed"
                target="_blank"
              >
                <img src={PlayBtn} alt="play-btn" className="play-btn" />
                <img src={VideoPreview} alt="preview-video" />
              </a>
            </div>
            <p className="heading">
              Lorem Ipsum is simply dummy text of the printing
            </p>
            <p className="sub-txt">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing
            </p>
          </div>
        </div>
      </section>
      {/* Mobile App page */}
      <section className="mobile-app active">
        <div className="inner-container">
          <div className="main-sec">
            <div className="left-sec">
              <p>
                Download Mobile app <br /> make it easy
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting{" "}
                <br />
                industry. Lorem Ipsum has been the industry's standard dummy{" "}
                <br />
                text ever since the 1500s, when an unknown printer took.
              </p>
              <p className="get-p">Get the App</p>
              <div className="badges">
                <img src={GooglePlay} alt="" />
                <img src={AppStore} alt="" />
              </div>
            </div>
            <div className="right-sec">
              <img src={PhoneMockup2} alt="" />
              <img src={PhoneMockup3} alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* Contact Us page */}
      <section className="contact-us" id="get-in-touch">
        <div className="inner-container">
          <div className="card">
            <p>Get in touch</p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor <br /> incididunt ut labore et dolore magna aliqua.{" "}
            </p>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="form-container">
                <div>
                  <input
                    type="text"
                    {...register("name", {
                      required: "Username is Required...",
                      minLength: {
                        value: 3,
                        message: "Name must be at least 3 characters long...",
                      },
                      maxLength: {
                        value: 30,
                        message: "Name must be at most 30 characters long...",
                      },
                    })}
                    placeholder="Full name *"
                  />
                  <p>{errors.name?.message}</p>
                </div>
                <div>
                  <input
                    type="text"
                    {...register("email", {
                      required: "Email is Required...",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Email ID must be valid!",
                      },
                    })}
                    placeholder="Email Address *"
                  />
                  <p>{errors.email?.message}</p>
                </div>
                <div>
                  <input
                    type="text"
                    {...register("phone_number", {})}
                    placeholder="Phone Number with Whatsapp"
                  />
                  <p>{errors.phone_number?.message}</p>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  {...register("message", {
                    required: "Message is Required...",
                    minLength: {
                      value: 10,
                      message: "Message must be at least 10 characters long...",
                    },
                  })}
                  placeholder="Message *"
                  className="message"
                />
                <p>{errors.message?.message}</p>
              </div>
              <div className="mt-30">
                <LoadCanvasTemplateNoReload />
              </div>
              <div>
                <input
                  type="text"
                  name=""
                  id="user_captcha_input"
                  placeholder="Enter captcha *"
                  className="captcha"
                />
              </div>
              <p className="note">
                You allow kyulebag.com to get in touch with you through email or
                whatsapp.
              </p>
              <input type="submit" value="Submit Now" />
            </form>
          </div>
          <footer>
            <NavLink to="/">
              <img src={LogoWhite} alt="logo-white" />
            </NavLink>
            <p>© Copyright 2023 kyulebag. All rights reserved</p>
            <div className="social">
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Instagram} alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Dribbble} alt="Dribbble" />
              </a>
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Twitter} alt="Twitter" />
              </a>
              <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Youtube} alt="Youtube" />
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedIn} alt="LinkedIn" />
              </a>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
}
