import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const statusOK = 201;

export const ToastAlert = () => (
  <ToastContainer
    position="bottom-center"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    pauseOnHover
    theme="dark"
  />
);
