import React from "react";
import { NavLink } from "react-router-dom";
import "../../assets/css/landing-page.css";
import Logo from "../../assets/images/logo-header.svg";

export function Privacy() {
  return (
    <div className="main-container">
      {/* main landing page */}
      <section className="main-landing privacy" id="home">
        <header>
          <div className="inner-container">
            <NavLink to="/">
              <img src={Logo} alt="logo" className="logo" />
            </NavLink>
            <span className="active">Privacy</span>
          </div>
        </header>
        <div className="inner-container">
          <p className="main-heading-privacy">Privacy Policy</p>
          <p className="h3">
            This Privacy Policy will help you better understand how we collect,
            use, and share your personal information.
          </p>
          <p>
            This privacy policy sets out how Creative Layer Inc. (“Creative
            Layer”, “we”, “us”, or “our”, and also doing business and “remx”)
            collects, uses, and discloses, any personal information that you
            give us or that we collect when you use our website or Services.
            Creative Layer offers a platform that allows artists, brands and us
            to mint and sell NFTs and tokens and for purchasers to mint and buy
            NFTs (“Services”). By using our website or Services, or by choosing
            to give us personal information, you consent to this Privacy Policy
            and the processing of your Personal Information it describes. If you
            do not agree with any terms of this Privacy Policy, please exercise
            the choices we describe in this Policy, or do not use the Services
            and do not give us any personal information.
          </p>
          <p>
            Creative Layer may change this policy from time to time by updating
            this page. You should check this page from time to time to ensure
            that you are happy with any changes. Your continued access to and/or
            use of our website or Services after any such changes constitutes
            your acceptance of, and agreement to this Privacy Policy, as
            revised.
          </p>
          <p>Privacy Summary</p>
          <ol class="">
            <li>
              <span class="text-black font-medium">
                {" "}
                1. What Personal Information we collect.{" "}
              </span>{" "}
              To register for our Services, you provide your public wallet
              address. If you make or buy an NFT, we collect information about
              your transaction, such as public wallet address, date and time,
              value, token ID, and transaction history. You may also choose to
              provide your email address, profile information such as name,
              social media handles, website, banner and profile picture, as well
              as other information you choose. We may also collect information
              to meet our anti-money laundering and ‘know your client’
              obligations.{" "}
            </li>
            <li>
              <span class="text-black font-medium">
                {" "}
                2. What we do with the Personal Information we collect.{" "}
              </span>{" "}
              We use the information we collect to provide our Services,
              including to allow you to register, make and buy NFTs, for record
              keeping, to improve our Services, to communicate with and respond
              to you, to manage, administer, maintain, service, collect and
              enforce our Terms and Conditions, to investigate and settle
              disputes, to investigate breaches, and for legal purposes,
              including anti-money laundering and ‘know your client’
              requirements.{" "}
            </li>
            <li>
              <span class="text-black font-medium">
                {" "}
                3. When we Disclose Personal Information{" "}
              </span>{" "}
              When you buy an NFT, your public wallet address, the date and
              time, the transaction value, and your token ID is recorded on the
              public block chain. We also share information with service
              providers, if we enter a business transaction, or for legal
              purposes.{" "}
            </li>
            <li>
              <span class="text-black font-medium">
                {" "}
                4. How we use cookies and collect information using technology{" "}
              </span>{" "}
              We use technologies such as cookies to collect IP address or
              device ID, operating system, browser type, username, the pages of
              our website that you visit, the time spent on those pages, access
              dates and times, clicks, links used, and actions you take on our
              site, errors you encounter, and general location derived from IP
              address. We use this to understand how our Services are used and
              improve them.{" "}
            </li>
            <li>
              <span class="text-black font-medium">5. Your Choices. </span> If
              you receive commercial email from us, you may unsubscribe from
              them. You may also choose to limit the personal information you
              provide to us, as described in this Privacy Policy.{" "}
            </li>
            <li>To allow you to register to use the Service. </li>
            <li>To allow you to create NFTs if you register as an artist. </li>
            <li>
              To allow you to purchase NFTs, to conduct the sale of NFTs, and to
              transfer ownership of the NFTs you purchase to you. This purchase
              uses block chain technology, and when you purchase an NFT, your
              public wallet address, the date and time of the transaction, the
              transaction value, and your token ID is recorded on the public
              block chain.{" "}
            </li>
            <li>For our internal record keeping. </li>
            <li>
              To improve our Services and the user experience on our platform.{" "}
            </li>
            <li>
              To send email and other communications about our Services, such as
              our newsletter, announcement of new collections, and special
              offers or other information which we think you may find
              interesting.{" "}
            </li>
            <li>
              To manage, administer, maintain, service, collect and enforce our
              Terms and Conditions, or any other agreement we have with you.{" "}
            </li>
            <li>To respond to your questions or complaints. </li>
            <li>
              To investigate and settle any dispute or claim which relates to
              our site, or Services, and/or to our Terms and Conditions.{" "}
            </li>
            <li>
              To investigate security breaches or cooperate with government
              authorities pursuant to a legal matter.{" "}
            </li>
            <li>
              To comply with applicable laws and regulations, including
              applicable anti-money laundering and ‘know your client’
              requirements.{" "}
            </li>
            <li>
              For other purposes which are disclosed to you and to which you
              consent.{" "}
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
}
