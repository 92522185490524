import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { publicRoutes } from "./routes/public/routes";
import { ToastAlert } from "./helper/constants";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {publicRoutes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
      <div>
        <ToastAlert />
      </div>
    </div>
  );
}

export default App;
